export function valideUser(new_user, new_user_init) {
  let err_msg = "";

  for (const key in new_user_init) {
    if (key == "functions") {
      continue;
    }

    if (key == "gender") {
      continue;
    }
    if (key == "name") {
      continue;
    }
    if (key == "send_password") {
      continue;
    }
    if (key == "password") {
      continue;
    }
    if (key == "confirm_email") {
      continue;
    }
    if (key == "internal_id") {
      continue;
    }
    if (new_user[key].trim() == new_user_init[key].trim()) {
      console.log(". === key === users.js === key: 500269 ===");
      console.log(key);
      console.log(new_user);
      console.log("=================================");
      err_msg =
        " Vous devez compléter le nouvel utilisateur avant d'enregistrer";
      return err_msg;
    }
  }

  /*let patern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,2000}$/;
  if (!new_user.password.trim().match(patern)) {
    err_msg =
      "Attention, le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre";

    return err_msg;
  }*/

  // eslint-disable-next-line no-useless-escape
  let patern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
  if (!new_user.email.trim().match(patern)) {
    err_msg = "Attention, cet email ne semble pas valide";

    return err_msg;
  }

  if (new_user.email != new_user.confirm_email && !new_user.user_token) {
    err_msg = "Attention, vous devez confirmer l'email correctement";

    return err_msg;
  }

  return err_msg;
}
