<template>
  <div class="new-element">
    <h2 v-if="this.item_to_update">Editer un challenge</h2>
    <h2 v-else-if="this.item_to_copy">Dupliquer un challenge</h2>
    <h2 v-else>
      {{ td("components.challenges.new_challenge.title.add_challenge") }}
    </h2>
    <div class="wrapper-content-row  premier-bloc">
      <div class="wrapper-content-column">
        <div class="bloc-content-circle set-position-relative">
          <h3>
            {{ td("components.challenges.new_challenge.title.challenge_name") }}
          </h3>
          <input
            v-if="editable == 'name'"
            ref="name"
            type="text"
            v-model="new_element.name"
            class="p"
            @blur="
              editable = '';
              testEmptyContent('name');
            "
          />
          <p
            class="hoverable"
            :data-hover="
              td('components.challenges.new_challenge.data_hover.add_name')
            "
            v-else
            @click="
              setprevious_content('name');
              editable = 'name';
            "
          >
            {{ new_element.name }}
          </p>
        </div>
        <div
          class="bloc-content-circle set-position-relative"
          style="flex:5"
          v-if="!item_to_update && !item_to_show"
        >
          <h3>
            {{ td("components.challenges.new_challenge.target") }}
          </h3>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_type == 'max_points' }
              ]"
              @click.stop="new_element.score_type = 'max_points'"
            ></span
            >{{ td("components.challenges.new_challenge.go_to_max_points") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_type == 'max_stars' }
              ]"
              @click.stop="new_element.score_type = 'max_stars'"
            ></span
            >{{ td("components.challenges.new_challenge.go_to_max_stars") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_type == 'max_elements' }
              ]"
              @click.stop="new_element.score_type = 'max_elements'"
            ></span
            >{{ td("components.challenges.new_challenge.go_to_max_actions") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_type == 'score_to' }
              ]"
              @click.stop="new_element.score_type = 'score_to'"
            ></span
            >{{ td("components.challenges.new_challenge.score_to") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_type == 'first_to' }
              ]"
              @click.stop="new_element.score_type = 'first_to'"
            ></span
            >{{ td("components.challenges.new_challenge.first_to") }}
          </h4>
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
        </div>
        <div
          class="bloc-content-circle set-position-relative"
          style="flex:5"
          v-else
        >
          <h3>
            {{ td("components.challenges.new_challenge.second_bloc.target") }}
          </h3>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_type == 'max_points' }
              ]"
            ></span
            >{{
              td(
                "components.challenges.new_challenge.second_bloc.go_to_max_points"
              )
            }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_type == 'max_stars' }
              ]"
            ></span
            >{{
              td(
                "components.challenges.new_challenge.second_bloc.go_to_max_stars"
              )
            }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_type == 'max_elements' }
              ]"
            ></span
            >{{
              td(
                "components.challenges.new_challenge.second_bloc.go_to_max_actions"
              )
            }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_type == 'score_to' }
              ]"
            ></span
            >{{
              td("components.challenges.new_challenge.second_bloc.score_to")
            }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_type == 'first_to' }
              ]"
            ></span
            >{{
              td("components.challenges.new_challenge.second_bloc.first_to")
            }}
          </h4>
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
          <h4 />
        </div>
        <div
          class="bloc-content-circle point-select"
          v-if="
            (new_element.score_type == 'first_to' ||
              new_element.score_type == 'score_to') &&
              !item_to_update &&
              !item_to_show
          "
        >
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_to_type == 'points' }
              ]"
              @click.stop="new_element.score_to_type = 'points'"
            ></span>
            <input
              ref="points_to_value"
              type="text"
              v-model="new_element.points_to_value"
            />{{ td("components.challenges.new_challenge.points") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_to_type == 'stars' }
              ]"
              @click.stop="new_element.score_to_type = 'stars'"
            ></span
            ><input
              ref="stars_to_value"
              type="text"
              v-model="new_element.stars_to_value"
            />{{ td("components.challenges.new_challenge.stars") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { clicked: new_element.score_to_type == 'elements' }
              ]"
              @click.stop="new_element.score_to_type = 'elements'"
            ></span
            ><input
              ref="elements_to_value"
              type="text"
              v-model="new_element.elements_to_value"
            />{{ td("components.challenges.new_challenge.actions") }}
          </h4>
        </div>
        <div
          class="bloc-content-circle point-select"
          v-else-if="
            new_element.score_type == 'first_to' ||
              new_element.score_type == 'score_to'
          "
        >
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_to_type == 'points' }
              ]"
            ></span>
            <input
              ref="points_to_value"
              type="text"
              v-model="new_element.points_to_value"
            />{{ td("components.challenges.new_challenge.points") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_to_type == 'stars' }
              ]"
            ></span
            ><input
              ref="stars_to_value"
              type="text"
              v-model="new_element.stars_to_value"
            />{{ td("components.challenges.new_challenge.stars") }}
          </h4>
          <h4>
            <span
              :class="[
                'circle',
                { grey: new_element.score_to_type == 'elements' }
              ]"
            ></span
            ><input
              ref="elements_to_value"
              type="text"
              v-model="new_element.elements_to_value"
            />{{ td("components.challenges.new_challenge.actions") }}
          </h4>
        </div>
      </div>
      <div class="wrapper-content-column">
        <div class="bloc-content-circle set-position-relative">
          <h3>
            {{ td("components.challenges.new_challenge.challenge_period") }}
          </h3>
          <p>
            {{ td("components.challenges.new_challenge.from") }}
            <v-date-picker
              v-model="new_element.start_date"
              :min-date="new Date()"
              :popover="{ placement: 'bottom', visibility: 'click' }"
              v-if="!dateNonEditable()"
            >
              <span class="pointer">{{ getStartDate() }}</span>
            </v-date-picker>
            <span class="" v-else>{{ getStartDate() }}</span>

            <v-date-picker
              v-model="new_element.end_date"
              :min-date="new_element.start_date"
              :popover="{ placement: 'bottom', visibility: 'click' }"
            >
              <span class="pointer">{{ getEndDate() }}</span>
            </v-date-picker>
          </p>
        </div>
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>
    <div
      class="wrapper-content-row second-bloc"
      v-if="challenge_type != 'by_function'"
    >
      <div class="wrapper-content-column container-users">
        <h3 v-if="challenge_type == 'inter_group'" style="position:relative">
          {{ td("components.challenges.new_challenge.concerned_groups") }}
        </h3>
        <h3 v-if="challenge_type == 'inter_team'">
          {{ td("components.challenges.new_challenge.concerned_teams") }}
        </h3>
        <h3 v-if="challenge_type == 'inside_team'">
          {{ td("components.challenges.new_challenge.concerned_sales") }}
        </h3>
        <h3 v-if="challenge_type == 'by_function'">
          {{ td("components.challenges.new_challenge.concerned_functions") }}
        </h3>
        <div class="wrapper-content-row " v-if="selectedUsers.length > 0">
          <div class="wrapper-content-row container-avatars">
            <div
              class="col-avatar"
              v-for="(item, index) in selectedUsers"
              :key="index"
            >
              <img :src="getProfilPicture(item)" alt="" />
            </div>
          </div>

          <div class="wrapper-content-row container-plus">
            <span
              class="plus-users"
              @click.stop="callback({ type: 'click-plus-users' })"
              v-if="!item_to_update && !item_to_show"
              >+</span
            >
          </div>
        </div>
        <div class="wrapper-content-row container-plus" v-else>
          <span
            class="plus-users"
            @click.stop="callback({ type: 'click-plus-users' })"
            v-if="!item_to_update && !item_to_show"
            >+</span
          >
        </div>
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>

    <div class="wrapper-content-row second-bloc" v-else>
      <div class="wrapper-content-column container-users">
        <h3>
          {{ td("components.challenges.new_challenge.concerned_functions") }}
        </h3>
        <div class="wrapper-content-row " v-if="selectedFunctions.length > 0">
          <div class="wrapper-content-column container-elements">
            <div
              class="col-element"
              v-for="(item, index) in selectedFunctions"
              :key="index"
            >
              <p>{{ item }}</p>
            </div>
          </div>

          <div class="wrapper-content-row container-plus">
            <span
              class="plus-users"
              @click.stop="callback({ type: 'click-plus-functions' })"
              >+</span
            >
          </div>
        </div>
        <div class="wrapper-content-row container-plus" v-else>
          <span
            class="plus-users"
            @click.stop="callback({ type: 'click-plus-functions' })"
            >+</span
          >
        </div>
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>

    <div
      class="wrapper-content-row middle-bloc"
      style="max-height:60px;min-height:60px;"
    >
      <div class="bloc-content-circle set-position-relative">
        <h4 class="infos">
          <span
            :class="['circle', { clicked: new_element.dissociate == true }]"
            @click.stop="new_element.dissociate = !new_element.dissociate"
            v-if="!item_to_update && !item_to_show"
          ></span>
          <span
            :class="['circle', { grey: new_element.dissociate == true }]"
            v-else
          ></span
          >{{ td("components.challenges.new_challenge.disociate_users") }}
          <span class="infos" @click.stop="info_cliqued = true">?</span>
        </h4>
        <br />
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>

    <div class="wrapper-content-row  second-bloc">
      <div class="wrapper-content-column container-users">
        <h3>
          {{ td("components.challenges.new_challenge.concerned_elements") }}
        </h3>
        <div class="wrapper-content-row " v-if="selectedElements.length > 0">
          <div class="wrapper-content-column container-elements">
            <div
              class="col-element"
              v-for="(item, index) in selectedElements"
              :key="index"
            >
              <p>{{ item.name }}</p>
            </div>
          </div>

          <div class="wrapper-content-row container-plus">
            <span
              v-if="!nonEditable()"
              class="plus-users"
              @click.stop="callback({ type: 'click-plus-elements' })"
              >+</span
            >
          </div>
        </div>
        <div class="wrapper-content-row container-plus" v-else>
          <span
            v-if="!nonEditable()"
            class="plus-users"
            @click.stop="callback({ type: 'click-plus-elements' })"
            >+</span
          >
        </div>
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>
    <div class="wrapper-content-row comment-bloc">
      <div class="bloc-content-circle set-position-relative comment-bloc">
        <h3>{{ td("components.challenges.new_challenge.comment") }}</h3>
        <textarea
          v-if="editable == 'comment'"
          ref="comment"
          type="text"
          v-model="new_element.comment"
          class="p"
          @blur="
            editable = '';
            testEmptyContent('comment');
          "
        ></textarea>
        <p
          class="hoverable"
          :data-hover="td('components.challenges.new_challenge.add_comment')"
          v-else
          @click="
            setprevious_content('comment');
            editable = 'comment';
          "
        >
          {{ new_element.comment }}
        </p>
      </div>
      <div :class="{ 'bloc-click': nonEditable() }"></div>
    </div>
    <div class="wrapper-content-row container-buttons last-bloc">
      <div class="bloc-periodes-footer" v-if="!nonEditable()">
        <button class="" @click.stop="saveElement()">
          {{ td("components.challenges.new_challenge.cta.valide") }}
        </button>
        <button class="negatif" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.challenges.new_challenge.cta.cancel") }}
        </button>
      </div>
      <div class="bloc-periodes-footer" v-else>
        <button class="" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.challenges.new_challenge.cta.ok") }}
        </button>
      </div>
    </div>
    <div class="transparent-lightbox-bg" v-if="info_cliqued != null"></div>
    <div
      class="wrapper-content-column show-comment"
      v-if="info_cliqued != null"
      style="left:550px; ;"
    >
      <p
        style="margin-bottom:0;"
        v-html="td('components.challenges.new_challenge.infos_1')"
      ></p>
      <p
        style="margin-bottom:0;"
        v-html="td('components.challenges.new_challenge.infos_2')"
      ></p>
      <p
        style="margin-bottom:0;"
        v-html="td('components.challenges.new_challenge.infos_3')"
      ></p>
      <p
        style="margin-bottom:0;"
        v-html="td('components.challenges.new_challenge.infos_4')"
      ></p>
      <button @click.stop="info_cliqued = null">ok</button>
    </div>
    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%;left:0"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import alertBox from "./../alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "NewChallenge",
  components: { alertBox },
  props: [
    "callback",
    "selectedUsers",
    "selectedElements",
    "selectedFunctions",
    "group_token",
    "team_token",
    "creator_token",
    "challenge_type",
    "item_to_copy",
    "item_to_update",
    "item_to_show",
    "new_user_selected"
  ],
  data: () => {
    return {
      td,
      info_cliqued: null,
      editable: "",
      //alert box
      show_alert_box: false,
      msg_alert: td("components.challenges.new_challenge.msg_alert"),
      alert_delay: 3000,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      previous_content: {
        new_element: {}
      },
      new_element_init: {
        name: td("components.challenges.new_challenge.new_element_init.name"),
        comment: td(
          "components.challenges.new_challenge.new_element_init.comment"
        ),
        undeclarative: false,
        score_type: "max_points",
        score_to_type: "points",
        points_to_value: 0,
        stars_to_value: 0,
        elements_to_value: 0,
        start_date: null,
        end_date: null,
        dissociate: false
      },
      new_element: {}
    };
  },
  methods: {
    dateNonEditable() {
      console.log("====================================");
      console.log();
      console.log("====================================");
      if (this.item_to_update || this.item_to_show) {
        console.log("====================== start_date ==============");
        console.log(this.item_to_update.start_date);
        console.log("====================================");

        if (this.item_to_update) {
          if (this.item_to_update.start_date > Date.now()) {
            return false;
          }
        }

        return true;
      }
      return false;
    },
    nonEditable() {
      if (this.item_to_update || this.item_to_copy || this.item_to_show) {
        console.log("=================== this.new_element =================");
        console.log(this.new_element);
        console.log("====================================");
        if (this.new_element.owner_data.email != this.user.email) {
          return true;
        }
      }
      return false;
    },
    getStartDate() {
      if (this.new_element.start_date == null) {
        return "xx/xx/xxx";
      }
      let dt = this.new_element.start_date;
      return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    },
    getEndDate() {
      if (this.new_element.end_date == null) {
        return "xx/xx/xxx";
      }
      let dt = this.new_element.end_date;
      return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    getProfilPicture(item) {
      let url = "/assets/img/profil.png";
      if (item.picture.trim() == "") {
        url = "/assets/img/profil.png";
      } else {
        url = axios.defaults.baseURL + "/images/" + item.picture;
      }
      return url;
    },
    setprevious_content(key) {
      this.previous_content.new_element[key] = this.new_element[key];
      if (this.new_element[key] == this.new_element_init[key]) {
        this.new_element[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_element[key].trim() == "") {
        this.new_element[key] = this.previous_content.new_element[key];
      }
    },
    saveElement() {
      let element = { ...this.new_element };
      if (element.name == this.new_element_init.name) {
        this.msg_alert = "Vous devez donner un nom à votre challenge";
        this.show_alert_box = true;
        return;
      }
      if (
        element.score_type == "score_to" ||
        element.score_type == "first_to"
      ) {
        /*points_to_value: 0,
        stars_to_value: 0,
        elements_to_value: 0,*/
        if (element.score_to_type == "points" && element.points_to_value == 0) {
          this.msg_alert =
            "Vous devez donner des points ou des étoiles à votre challenge";
          this.show_alert_box = true;
          return;
        }
        if (element.score_to_type == "stars" && element.stars_to_value == 0) {
          this.msg_alert =
            "Vous devez donner des points ou des étoiles à votre challenge";
          this.show_alert_box = true;
          return;
        }
        if (
          element.score_to_type == "elements" &&
          element.elements_to_value == 0
        ) {
          this.msg_alert =
            "Vous devez donner un nombre d'éléments à atteindre à votre challenge";
          this.show_alert_box = true;
          return;
        }

        //this.show_alert_box = true;
        //return;
      }
      if (!element.start_date) {
        this.msg_alert =
          "Vous devez donner une date de début à votre challenge";
        this.show_alert_box = true;
        return;
      }
      if (!element.end_date) {
        this.msg_alert = "Vous devez donner une date de fin à votre challenge";
        this.show_alert_box = true;
        return;
      }

      if (element.end_date.getTime() <= element.start_date.getTime()) {
        this.msg_alert =
          "La date de fin de votre challenge ne peut pas être avant la date de début";
        this.show_alert_box = true;
        return;
      }
      if (!this.selectedFunctions && this.selectedUsers) {
        if (this.selectedUsers.length == 0) {
          this.msg_alert =
            "Vous devez sélectionner des groupes, des équipes ou des commerciaux pour votre challenge";
          this.show_alert_box = true;
          return;
        }
      }
      if (this.selectedFunctions && !this.selectedUsers) {
        if (this.selectedFunctions.length == 0) {
          this.msg_alert =
            "Vous devez sélectionner une ou plusieurs fonctions pour votre challenge";
          this.show_alert_box = true;
          return;
        }
      }

      if (this.selectedElements.length == 0) {
        this.msg_alert =
          "Vous devez sélectionner des éléments pour votre challenge";
        this.show_alert_box = true;
        return;
      }
      if (element.comment == this.new_element_init.comment) {
        element.comment = "";
      }

      console.log("====================================");
      console.log();
      console.log("====================================");

      if (!element.selected_users) {
        element.selected_users = this.selectedUsers;
      }
      //element.selected_users = this.selectedUsers;
      element.selected_elements = this.selectedElements;
      element.selected_functions = this.selectedFunctions;
      element.selected_user_tokens = [];
      element.selected_group_tokens = [];
      element.selected_team_tokens = [];
      if (element.selected_users) {
        for (let i = 0; i < element.selected_users.length; i++) {
          const u = element.selected_users[i];
          element.selected_user_tokens.push(u.user_token);
          element.selected_group_tokens.push(u.group_token);
          element.selected_team_tokens.push(u.team_token);
        }
      }

      element.owner_token = this.user.user_token;
      element.owner_data = this.user;
      element.circle_token = this.circle.circle_token;
      element.group_token = this.group_token;
      element.team_token = this.team_token;
      element.start_date = element.start_date.getTime();
      element.end_date = element.end_date.getTime();
      element.challenge_type = this.challenge_type;
      if (this.new_user_selected) {
        element.new_user_selected = this.new_user_selected;
        element.admin_level = this.new_user_selected.admin_level;
      }
      element.status = "open";

      if (element.start_date > new Date().getTime()) {
        element.status = "waiting";
      }

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        group_token: this.group_token,
        team_token: this.team_token
      };

      console.log("================= element ===================");
      console.log(element);
      console.log("====================================");

      //return;
      // eslint-disable-next-line no-unreachable
      let sendData = { credentials, challenge: element, filters };

      let url = "/challenges/new";

      if (this.item_to_update) {
        url = "/challenges/update";
      }

      axios
        .post(url, sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.msg_alert = "Le challenge a été envoyé";
            context.show_alert_box = true;
            context.new_element = JSON.parse(
              JSON.stringify(context.new_element_init)
            );

            context.callback({ type: "element-saved" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.new_element = JSON.parse(JSON.stringify(this.new_element_init));
    if (this.item_to_copy) {
      this.new_element = JSON.parse(JSON.stringify(this.item_to_copy));
      this.new_element.start_date = null;
      this.new_element.end_date = null;
      delete this.new_element.challenge_token;
    }
    if (this.item_to_update) {
      this.new_element = JSON.parse(JSON.stringify(this.item_to_update));
      this.new_element.start_date = new Date(this.new_element.start_date);
      this.new_element.end_date = new Date(this.new_element.end_date);
      console.log("================= new_element ===================");
      console.log(this.new_element);
      console.log("====================================");
      //delete this.new_element.challenge_token;
    }
    if (this.item_to_show) {
      this.new_element = JSON.parse(JSON.stringify(this.item_to_show));
      this.new_element.start_date = new Date(this.new_element.start_date);
      this.new_element.end_date = new Date(this.new_element.end_date);
      //delete this.new_element.challenge_token;
    }

    if (this.new_element.comment.trim() == "") {
      this.new_element.comment = this.new_element_init.comment;
    }
  }
};
</script>
