<template>
  <section class="content content-column ceo-column">
    <h2>
      {{ td("components.group.sales_column.title.sales") }}
      <span class="plus" @click.stop="clickHandler({ type: 'click-plus' })"
        >+</span
      >
    </h2>
    <div class="body-content">
      <div
        class="user-item"
        v-for="(item, index) in users"
        :key="index"
        @click.stop="clickItemHandler(item)"
      >
        <div
          :class="[
            'container-avatar-body',
            {
              'container-avatar-body-clicked':
                item.user_token == clicked_user_token
            }
          ]"
        >
          <div class="col-avatar">
            <img :src="getProfilPicture(item)" alt="" />
          </div>
          <div class="col-body">
            <h2>{{ item.firstname + " " + item.lastname }}</h2>
            <h3>{{ item.functions[0] }}</h3>
            <h4>{{ item.team_name }}</h4>
          </div>
        </div>
        <div class="col-buttons">
          <div
            :class="['pen', { empty: !getIsPen(item) }]"
            @click.stop="editUser(item)"
          ></div>
          <div
            :class="['cross', { empty: !getIsCross(item) }]"
            @click="deleteUser(item)"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import router from "./../../router/index";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "saleColumn",
  props: {
    props_circle_token: {
      type: String,
      required: true
    },
    props_group_token: {
      type: String,
      required: true
    },
    props_team_token: {
      type: String,
      required: true
    },
    callback: {
      type: Function,
      default: null,
      required: true
    }
  },
  data: function() {
    return {
      td,
      admin_levels: {},
      user: {},
      users: [],
      admin_level: "",
      circle_token: null,
      group_token: null,
      team_token: null,
      clicked_user_token: ""
    };
  },
  methods: {
    getIsPen(item) {
      if (!item.owner) {
        return false;
      }
      if (item.owner == this.$store.state.userToken) {
        return true;
      }
      if (item.user_token == this.$store.state.userToken) {
        return true;
      }

      if (this.$store.getters.adminValue >= 2) {
        return true;
      }
      return false;
    },
    getIsCross(item) {
      return true;
      // eslint-disable-next-line no-unreachable
      if (this.users.length <= 1) {
        //return false;
      }

      if (this.$store.getters.adminValue < 2) {
        return false;
      }

      let total = 0;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].group_token == item.group_token) {
          total++;
        }
      }
      if (total <= 1) {
        return false;
      }

      if (item.user_token == this.$store.state.userToken) {
        return false;
      }
      if (this.$store.getters.adminValue >= 8) {
        //return true;
      }

      return true;
    },
    clickHandler(obj) {
      obj.group_token = this.group_token;
      this.callback(obj);
    },
    clickItemHandler(item) {
      this.clicked_user_token = item.user_token;
      this.callback({ type: "click-sale", sale_token: item.sale_token });
    },
    getProfilPicture(item) {
      let url = "/assets/img/profil.png";
      if (item.picture.trim() == "") {
        url = "/assets/img/profil.png";
      } else {
        url = axios.defaults.baseURL + "/images/" + item.picture;
      }
      return url;
    },
    doCallback(obj) {
      this.callback(obj);
    },

    editUser(item) {
      if (!this.getIsPen(item)) {
        return;
      }
      this.$router.push({
        path:
          "/teams/edit-user-sale?type=sale&ut=" +
          item.user_token +
          "&team_token=" +
          item.team_token
      });
    },

    deleteUser(item) {
      if (!this.getIsCross(item)) {
        return;
      }
      this.callback({ type: "delete_user", item, doDelete: this.doDeleteUser });
    },
    doDeleteUser(item) {
      if (!this.getIsCross(item)) {
        return;
      }
      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        user_token: item.user_token,
        circle_token: this.$store.state.circleToken
      };

      const sendData = {
        credentials,
        filters
      };
      axios
        .post("/users/delete", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            store.dispatch("refreshTokenId", authData);
            context.callback({ type: "user_deleted" });
            setTimeout(() => {
              context.getUsers();
            }, 150);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {});
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      store;
      router;
      context;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        admin_level: "sale",
        team_token: this.team_token || "///"
      };

      const sendData = {
        credentials,
        filters
      };
      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };
            context.users = data.response.users;
            store.dispatch("refreshTokenId", authData);
            context.callback({ type: "loaded" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {});
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle_token = this.props_circle_token;
    this.group_token = this.props_group_token;
    this.team_token = this.props_team_token;

    this.getUsers();
  }
};
</script>
