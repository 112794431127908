<template>
  <div class="new-element">
    <h2>{{ td("components.catalog.show_element.title.consult_element") }}</h2>
    <div class="wrapper-content-row">
      <div class="wrapper-content-column">
        <div class="bloc-content-circle set-position-relative">
          <h3>{{ td("components.catalog.show_element.element_name") }}</h3>
          <p>
            {{ new_element.name }}
          </p>
        </div>
        <div class="bloc-content-circle set-position-relative">
          <h3>{{ td("components.catalog.show_element.points") }}</h3>

          <p>
            {{ new_element.points }}
          </p>
        </div>
        <div class="bloc-content-circle set-position-relative">
          <h3>
            {{ td("components.catalog.show_element.photo_mandatory")
            }}<span
              :class="['circle', { clicked: new_element.need_picture == true }]"
            ></span>
          </h3>
        </div>
      </div>
      <div class="wrapper-content-column">
        <div class="bloc-content-circle set-position-relative"></div>
        <div class="bloc-content-circle set-position-relative">
          <h3>{{ td("components.catalog.show_element.stars") }}</h3>

          <p>
            {{ new_element.stars }}
          </p>
        </div>
        <div class="bloc-content-circle set-position-relative">
          <h3>
            {{ td("components.catalog.show_element.non_declarative")
            }}<span
              :class="[
                'circle',
                { clicked: new_element.undeclarative == true }
              ]"
            ></span>
          </h3>
        </div>
      </div>
    </div>
    <div class="wrapper-content-row">
      <div class="wrapper-content-column container-users">
        <h3 v-if="new_element.level == 'ceo_manager'">
          {{ td("components.catalog.show_element.grouos_concerned") }}
        </h3>
        <h3 v-if="new_element.level == 'group_manager'">
          {{ td("components.catalog.show_element.teams_concerned") }}
        </h3>
        <h3 v-if="new_element.level == 'team_manager'">
          {{ td("components.catalog.show_element.sales_concerned") }}
        </h3>
        <div class="wrapper-content-row " v-if="selectedUsers.length > 0">
          <div class="wrapper-content-row container-avatars">
            <div
              class="col-avatar"
              v-for="(item, index) in selectedUsers"
              :key="index"
            >
              <img :src="getProfilPicture(item)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-content-row">
      <div class="bloc-content-circle set-position-relative">
        <h3>{{ td("components.catalog.show_element.otional_comments") }}</h3>
        <p
          class="hoverable"
          data-hover="td('components.catalog.show_element.data_hover.put_comment')"
        >
          {{ new_element.comment }}
        </p>
      </div>
    </div>
    <div class="wrapper-content-row container-buttons">
      <div class="bloc-periodes-footer">
        <button class="" @click.stop="callback({ type: 'cancel' })">
          {{ td("components.catalog.show_element.cta.valide") }}
        </button>
      </div>
    </div>
    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import alertBox from "./../alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "UpdateElement",
  components: { alertBox },
  props: [
    "callback",
    "level",
    "selectedUsers",
    "elementParams",
    "group_token",
    "team_token"
  ],
  data: () => {
    return {
      editable: "",

      //alert box
      show_alert_box: false,
      msg_alert: td("components.catalog.show_element.msg_alert"),
      alert_delay: 3000,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      previous_content: {
        new_element: {}
      },
      new_element_init: {
        name: td("components.catalog.show_element.new_element_init.name"),
        comment: td("components.catalog.show_element.new_element_init.comment"),
        points: 0,
        stars: 0,
        need_picture: false,
        undeclarative: false
      },
      new_element: {}
    };
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    getProfilPicture(item) {
      let url = "/assets/img/profil.png";
      if (item.picture.trim() == "") {
        url = "/assets/img/profil.png";
      } else {
        url = axios.defaults.baseURL + "/images/" + item.picture;
      }
      return url;
    },
    setprevious_content(key) {
      this.previous_content.new_element[key] = this.new_element[key];
      if (this.new_element[key] == this.new_element_init[key]) {
        this.new_element[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_element[key].trim() == "") {
        this.new_element[key] = this.previous_content.new_element[key];
      }
    },
    saveElement() {
      let element = { ...this.new_element };
      if (element.name == this.new_element_init.name) {
        this.msg_alert = "Vous devez donner un nom à votre élément";
        this.show_alert_box = true;
        return;
      }
      if (element.points == 0 && element.stars == 0) {
        this.msg_alert =
          "Vous devez donner des points ou des étoiles à votre élément";
        //this.show_alert_box = true;
        //return;
      }
      if (element.comment == this.new_element_init.comment) {
        element.comment = "";
      }
      element.selected_users = this.selectedUsers;

      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      let filters = {
        circle_token: this.circle.circle_token,
        group_token: this.group_token,
        team_token: this.team_token,
        level: this.level
      };

      let sendData = { credentials, element, filters, level: this.level };

      //return;

      // eslint-disable-next-line no-unreachable
      axios
        .post("/elements/update", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.msg_alert = "L'élément a été enregistré";
            context.show_alert_box = true;
            context.new_element = JSON.parse(
              JSON.stringify(context.new_element_init)
            );

            context.callback({ type: "element-saved" });
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.new_element = JSON.parse(JSON.stringify(this.elementParams));
    if (this.new_element.comment == "") {
      this.new_element.comment = this.new_element_init.comment;
    }
  }
};
</script>
