<template>
  <main>
    <div class="wrapper team-list">
      <ceoColumn
        :callback="ceoColumnCallback"
        v-if="$store.getters.adminValue >= 8"
        :props_circle_token="$store.state.circleToken"
      />
      <groupColumn
        :callback="groupColumnCallback"
        v-if="circle_token != ''"
        :props_circle_token="circle_token"
      />
      <teamColumn
        :callback="teamColumnCallback"
        v-if="comp_group_token != ''"
        :props_circle_token="circle_token"
        :props_group_token="comp_group_token"
      />
      <saleColumn
        :callback="saleColumnCallback"
        v-if="$store.getters.adminValue >= 2 && comp_team_token != ''"
        :props_circle_token="circle_token"
        :props_group_token="comp_group_token"
        :props_team_token="comp_team_token"
      />
      <transition name="fade">
        <div class="container-alert" v-if="show_alert_box === true">
          <alertBox
            :msg="msg_alert"
            :callback="alertCallback"
            :delay="alert_delay"
          />
        </div>
      </transition>
      <transition name="fade">
        <div class="container-alert" v-if="show_confirm_box === true">
          <confirmBox
            :msg1="msg_confirm1"
            :msg2="msg_confirm2"
            :title="title_confirm"
            :callback="confirmCallback"
            :delay="confirm_delay"
          />
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
/* eslint-disable vue/no-unused-components */
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import headerComponent from "./../../components/header/headerComponent";
import ceoColumn from "./../../components/ceo/ceoColumn";
import groupColumn from "./../../components/group/groupColumn";
import teamColumn from "./../../components/team/teamColumn";
import saleColumn from "./../../components/sale/saleColumn";
import alertBox from "./../../components/alertBoxes/alertBox";
import confirmBox from "./../../components/alertBoxes/confBox";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "Teams",
  components: {
    headerComponent,
    ceoColumn,
    groupColumn,
    teamColumn,
    saleColumn,
    alertBox,
    confirmBox
  },
  data: () => {
    return {
      td,
      lastFunctionToCall: null,
      lastParamsToCall: null,
      admin_levels: {},
      user: {},
      admin_level: "",
      do_new_ceo: false,
      circle_token: "",
      //alert box
      show_alert_box: false,
      msg_alert: "test alert",
      alert_delay: 3000,
      //confirm box
      show_confirm_box: false,
      msg_confirm1: "test confirm box",
      msg_confirm2: "test confirm box",
      title_confirm: "test title",
      confirm_delay: 0,
      //
      group_token: "",
      team_token: ""
    };
  },
  computed: {
    comp_group_token() {
      return this.group_token;
    },
    comp_team_token() {
      return this.team_token;
    }
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    confirmCallback(obj) {
      if (obj.type == "click-annuler") {
        this.show_confirm_box = false;
      }
      if (obj.type == "click-valider") {
        this.show_confirm_box = false;
        if (this.lastFunctionToCall) {
          if (this.lastParamsToCall) {
            this.lastFunctionToCall(this.lastParamsToCall);
          } else {
            this.lastFunctionToCall();
          }
        }
        this.lastFunctionToCall = null;
        this.lastParamsToCall = null;
      }
      if (obj.type == "end_delay") {
        this.show_confirm_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
      /*if (obj.type == "click-profil") {
        this.$router.push({ path: "/edit-profil" });
      }*/
    },
    ceoColumnCallback(obj) {
      if (obj.type == "delete_user") {
        let func = obj.doDelete;
        let item = obj.item;
        this.confirm_delay = 0;
        this.msg_confirm1 = td("views.teams.list.msg_confirm1");
        this.msg_confirm2 = td("views.teams.list.msg_confirm2");
        this.title_confirm = td("views.teams.list.title_confirm");
        this.lastFunctionToCall = func;
        this.lastParamsToCall = item;
        this.show_confirm_box = true;

        return;
      }
      if (obj.type == "user_deleted") {
        this.calert_delay = 3500;
        this.msg_alert = "Ce profil de directeur a bien été supprimé.";
        this.show_alert_box = true;

        return;
      }
      if (obj.type == "click-plus") {
        //this.do_new_ceo = true;
        this.$router.push({ path: "/teams/new-user?type=ceo_manager" });
        return;
      }
      if (obj.type == "click-ceo") {
        //this.do_new_ceo = true;
        this.group_token = "";
        this.team_token = "";
        return;
      }
      if (obj.type == "loaded") {
        //this.do_new_ceo = true;
        this.circle_token = this.$store.state.circleToken;
        return;
      }
    },
    groupColumnCallback(obj) {
      if (obj.type == "delete_user") {
        let func = obj.doDelete;
        let item = obj.item;
        this.confirm_delay = 0;
        this.msg_confirm1 =
          "Voulez-vous supprimer définitivement ce profil de responsable ?";
        this.msg_confirm2 = 'Clickez sur "Valider" pour le supprimer.';
        this.title_confirm = "Supprimer un resp. de groupe";
        this.lastFunctionToCall = func;
        this.lastParamsToCall = item;
        this.show_confirm_box = true;

        return;
      }
      if (obj.type == "user_deleted") {
        this.calert_delay = 3500;
        this.msg_alert = "Ce profil de responsable a bien été supprimé.";
        this.show_alert_box = true;

        return;
      }
      if (obj.type == "click-plus") {
        //this.do_new_ceo = true;
        this.$router.push({ path: "/teams/new-user?type=group_manager" });
        return;
      }
      if (obj.type == "click-group") {
        //this.do_new_ceo = true;
        this.team_token = "";
        this.$router.push({
          path:
            "/teams/list?group_token=" +
            obj.group_token +
            "&group_user_token=" +
            obj.clicked_user_token
        });
        if (this.group_token != "") {
          this.group_token = "";
        }
        if (this.team_token != "") {
          this.team_token = "";
        }
        setTimeout(() => {
          this.group_token = obj.group_token;
        }, 150);

        return;
      }
      if (obj.type == "loaded") {
        if (this.$route.query.group_token) {
          this.group_token = this.$route.query.group_token;
          return;
        }
        if (this.$store.getters.adminValue <= 4) {
          this.group_token = this.$store.state.userData.group_token;
          return;
        }
      }
    },
    teamColumnCallback(obj) {
      if (obj.type == "delete_user") {
        let func = obj.doDelete;
        let item = obj.item;
        this.confirm_delay = 0;
        this.msg_confirm1 =
          "Voulez-vous supprimer définitivement ce profil de superviseur ?";
        this.msg_confirm2 = 'Clickez sur "Valider" pour le supprimer.';
        this.title_confirm = "Supprimer un superviseur";
        this.lastFunctionToCall = func;
        this.lastParamsToCall = item;
        this.show_confirm_box = true;

        return;
      }
      if (obj.type == "user_deleted") {
        this.calert_delay = 3500;
        this.msg_alert = "Ce profil de superviseur a bien été supprimé.";
        this.show_alert_box = true;

        return;
      }
      if (obj.type == "click-plus") {
        this.$router.push({
          path:
            "/teams/new-user?type=team_manager&group_token=" + obj.group_token
        });
        return;
      }
      if (obj.type == "click-team") {
        //this.do_new_ceo = true;

        this.$router.push({
          path:
            "/teams/list?group_token=" +
            this.group_token +
            "&team_token=" +
            obj.team_token +
            "&team_user_token=" +
            obj.clicked_user_token +
            "&group_user_token=" +
            this.$route.query.group_user_token
        });
        if (this.team_token != "") {
          this.team_token = "";
        }
        setTimeout(() => {
          this.team_token = obj.team_token;
        }, 150);

        return;
      }
      if (obj.type == "loaded") {
        if (this.$route.query.team_token) {
          this.team_token = this.$route.query.team_token;
          return;
        }
        if (this.$store.getters.adminValue <= 2) {
          //this.team_token = this.$store.state.userData.team_token;
          return;
        }
      }
    },
    saleColumnCallback(obj) {
      if (obj.type == "delete_user") {
        let func = obj.doDelete;
        let item = obj.item;
        this.confirm_delay = 0;
        this.msg_confirm1 =
          "Voulez-vous supprimer définitivement ce profil de commercial ?";
        this.msg_confirm2 = 'Clickez sur "Valider" pour le supprimer.';
        this.title_confirm = "Supprimer un commercial";
        this.lastFunctionToCall = func;
        this.lastParamsToCall = item;
        this.show_confirm_box = true;

        return;
      }
      if (obj.type == "user_deleted") {
        this.calert_delay = 3500;
        this.msg_alert = "Ce profil de commercial a bien été supprimé.";
        this.show_alert_box = true;

        return;
      }
      if (obj.type == "click-plus") {
        this.$router.push({
          path:
            "/teams/new-sale?type=sale&group_token=" +
            this.group_token +
            "&team_token=" +
            this.team_token
        });
        return;
      }
      if (obj.type == "click-team") {
        //this.do_new_ceo = true;

        this.$router.push({
          path:
            "/teams/list?group_token=" +
            this.group_token +
            "&team_token=" +
            obj.team_token
        });
        if (this.team_token != "") {
          this.team_token = "";
        }
        setTimeout(() => {
          this.team_token = obj.team_token;
        }, 150);

        return;
      }
      if (obj.type == "loaded") {
        if (this.$route.query.team_token) {
          this.team_token = this.$route.query.team_token;
          return;
        }
        if (this.$store.getters.adminValue <= 2) {
          this.team_token = this.$store.state.userData.team_token;
          return;
        }
      }
    },
    adminLevelValue() {
      if (this.admin_levels[this.admin_level]) {
        return this.admin_levels[this.admin_level].value;
      }
    }
  },
  created() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    //this.circle_token = this.$store.state.circleToken;
    if (this.$store.getters.adminValue < 8) {
      setTimeout(() => {
        this.circle_token = this.$store.state.circleToken;
      }, 150);
    }
    /* if (this.$store.getters.adminValue < 4) {
      setTimeout(() => {
        this.team_token = this.user.team_token;
      }, 250);
    }*/

    ("=========== this.circle_token ======== created() { =================");
  },
  mounted() {
    if (this.$route.query.team_token) {
      //this.group_token = this.$route.query.team_token;
    }
    if (this.$route.query.group_token) {
      //this.group_token = this.$route.query.group_token;
    }
  }
};
</script>
