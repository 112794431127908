<template>
  <div class="wrapper-content-column">
    <div class="wrapper-content">
      <div class="column-notifications" v-if="$store.getters.adminValue == 8">
        <h2 v-html="td('views.params.params_notifications.title_ceo')">
          <span
            :class="['circle', { clicked: getClickAllCeo() }]"
            @click.stop="clickAllCeo()"
          ></span>
        </h2>
        <h3
          v-html="
            td('views.params.params_notifications.ceo.select_your_notifs')
          "
        ></h3>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.ceo.objectifs") }}</h2>
          <div>
            {{ td("views.params.params_notifications.ceo.more_than_half")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.objectif2 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.objectif2 =
                  notifications.ceo_manager.objectif2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.more_than_75pct") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.objectif3 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.objectif3 =
                  notifications.ceo_manager.objectif3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.more_than_all")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.objectif4 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.objectif4 =
                  notifications.ceo_manager.objectif4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.perso")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.objectif1 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.objectif1 =
                  notifications.ceo_manager.objectif1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif" style="min-height:178px">
          <h2>{{ td("views.params.params_notifications.ceo.challenges") }}</h2>
          <div>
            {{ td("views.params.params_notifications.ceo.challenges.machin")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.challenges_name == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.challenges_name =
                  notifications.ceo_manager.challenges_name == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.ceo.records") }}</h2>
          <div>
            {{ td("views.params.params_notifications.ceo.records_team")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.record2 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.record2 =
                  notifications.ceo_manager.record2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.records_group") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.record3 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.record3 =
                  notifications.ceo_manager.record3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.records_company")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.record4 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.record4 =
                  notifications.ceo_manager.record4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.ceo.records_perso") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.ceo_manager.record1 == 1 }
              ]"
              @click.stop="
                notifications.ceo_manager.record1 =
                  notifications.ceo_manager.record1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
      </div>
      <div
        class="column-notifications"
        v-if="
          ($store.getters.adminValue == 8 && group_profils.length) ||
            $store.getters.adminValue == 4
        "
      >
        <h2>
          {{ td("views.params.params_notifications.title_group") }}
          <span
            :class="['circle', { clicked: getClickAllGroup() }]"
            @click.stop="clickAllGroup()"
          ></span>
        </h2>
        <h3
          v-html="
            td('views.params.params_notifications.group.select_your_notifs')
          "
        ></h3>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.group.objectifs") }}</h2>
          <div>
            {{ td("views.params.params_notifications.group.more_than_half")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.group_manager.objectif2 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.objectif2 =
                  notifications.group_manager.objectif2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.more_than_75pct") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.objectif3 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.objectif3 =
                  notifications.group_manager.objectif3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.more_than_all") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.objectif4 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.objectif4 =
                  notifications.group_manager.objectif4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.perso") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.objectif1 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.objectif1 =
                  notifications.group_manager.objectif1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>
            {{ td("views.params.params_notifications.group.challenges") }}
          </h2>
          <div>
            {{ td("views.params.params_notifications.group.challenges.machin")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.group_manager.challenges_name == 1 }
              ]"
              @click.stop="
                notifications.group_manager.challenges_name =
                  notifications.group_manager.challenges_name == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div
            v-html="
              td(
                'views.params.params_notifications.group.challenges.new_challenge'
              )
            "
          >
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.challenges_new == 1 }
              ]"
              @click.stop="
                notifications.group_manager.challenges_new =
                  notifications.group_manager.challenges_new == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.challenges.won") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.challenges_won == 1 }
              ]"
              @click.stop="
                notifications.group_manager.challenges_won =
                  notifications.group_manager.challenges_won == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.challenges.lost") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.challenges_lost == 1 }
              ]"
              @click.stop="
                notifications.group_manager.challenges_lost =
                  notifications.group_manager.challenges_lost == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.group.records") }}</h2>
          <div>
            {{ td("views.params.params_notifications.group.records_team")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.group_manager.record2 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.record2 =
                  notifications.group_manager.record2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.records_group") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.record3 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.record3 =
                  notifications.group_manager.record3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.records_company") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.record4 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.record4 =
                  notifications.group_manager.record4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.group.records_perso") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.group_manager.record1 == 1 }
              ]"
              @click.stop="
                notifications.group_manager.record1 =
                  notifications.group_manager.record1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
      </div>
      <div
        class="column-notifications"
        v-if="
          ($store.getters.adminValue == 8 && team_profils.length) ||
            $store.getters.adminValue == 4 ||
            $store.getters.adminValue == 2
        "
      >
        <h2 v-html="td('views.params.params_notifications.title_team')">
          <span
            :class="['circle', { clicked: getClickAllTeam() }]"
            @click.stop="clickAllTeam()"
          ></span>
        </h2>
        <h3
          v-html="
            td('views.params.params_notifications.team.select_your_notifs')
          "
        ></h3>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.team.objectifs") }}</h2>
          <div>
            {{ td("views.params.params_notifications.team.more_than_half")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.team_manager.objectif2 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.objectif2 =
                  notifications.team_manager.objectif2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.more_than_75pct") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.objectif3 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.objectif3 =
                  notifications.team_manager.objectif3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.more_than_all") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.objectif4 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.objectif4 =
                  notifications.team_manager.objectif4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.perso") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.objectif1 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.objectif1 =
                  notifications.team_manager.objectif1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.team.challenges") }}</h2>
          <div>
            {{ td("views.params.params_notifications.team.challenges.machin") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.challenges_name == 1 }
              ]"
              @click.stop="
                notifications.team_manager.challenges_name =
                  notifications.team_manager.challenges_name == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.team.challenges.new_challenge"
              )
            }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.challenges_new == 1 }
              ]"
              @click.stop="
                notifications.team_manager.challenges_new =
                  notifications.team_manager.challenges_new == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.challenges.won") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.challenges_won == 1 }
              ]"
              @click.stop="
                notifications.team_manager.challenges_won =
                  notifications.team_manager.challenges_won == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.challenges.lost") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.challenges_lost == 1 }
              ]"
              @click.stop="
                notifications.team_manager.challenges_lost =
                  notifications.team_manager.challenges_lost == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.team.records") }}</h2>
          <div>
            {{ td("views.params.params_notifications.team.records_team")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.team_manager.record2 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.record2 =
                  notifications.team_manager.record2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.records_group") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.record3 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.record3 =
                  notifications.team_manager.record3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.records_company") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.record4 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.record4 =
                  notifications.team_manager.record4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.team.records_perso") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.team_manager.record1 == 1 }
              ]"
              @click.stop="
                notifications.team_manager.record1 =
                  notifications.team_manager.record1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
      </div>
      <div class="column-notifications" v-if="$store.getters.adminValue == 8">
        <h2 v-html="td('views.params.params_notifications.title_sale')">
          <span
            :class="['circle', { clicked: getClickAllSale() }]"
            @click.stop="clickAllSale()"
          ></span>
        </h2>
        <h3
          v-html="
            td('views.params.params_notifications.sale.select_your_notifs')
          "
        ></h3>
        <div class="bloc-notif" style="min-height:206px">
          <h2>{{ td("views.params.params_notifications.sale.objectifs") }}</h2>
          <div>
            {{ td("views.params.params_notifications.sale.more_than_half")
            }}<span
              :class="[
                'circle',
                { clicked: notifications.sale.objectif2 == 1 }
              ]"
              @click.stop="
                notifications.sale.objectif2 =
                  notifications.sale.objectif2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.more_than_75pct") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.objectif3 == 1 }
              ]"
              @click.stop="
                notifications.sale.objectif3 =
                  notifications.sale.objectif3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.more_than_all") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.objectif4 == 1 }
              ]"
              @click.stop="
                notifications.sale.objectif4 =
                  notifications.sale.objectif4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.nex_objectifs") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.objectif1 == 1 }
              ]"
              @click.stop="
                notifications.sale.objectif1 =
                  notifications.sale.objectif1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif" style="min-height:178px">
          <h2>{{ td("views.params.params_notifications.sale.challenges") }}</h2>
          <div
            v-html="
              td(
                'views.params.params_notifications.sale.challenges.new_challenge'
              )
            "
          >
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.challenges_new == 1 }
              ]"
              @click.stop="
                notifications.sale.challenges_new =
                  notifications.sale.challenges_new == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.challenges.won") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.challenges_won == 1 }
              ]"
              @click.stop="
                notifications.sale.challenges_won =
                  notifications.sale.challenges_won == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.challenges.lost") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.challenges_lost == 1 }
              ]"
              @click.stop="
                notifications.sale.challenges_lost =
                  notifications.sale.challenges_lost == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>{{ td("views.params.params_notifications.sale.records") }}</h2>
          <div>
            {{ td("views.params.params_notifications.sale.records_team")
            }}<span
              :class="['circle', { clicked: notifications.sale.record2 == 1 }]"
              @click.stop="
                notifications.sale.record2 =
                  notifications.sale.record2 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.records_group") }}
            <span
              :class="['circle', { clicked: notifications.sale.record3 == 1 }]"
              @click.stop="
                notifications.sale.record3 =
                  notifications.sale.record3 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.records_company") }}
            <span
              :class="['circle', { clicked: notifications.sale.record4 == 1 }]"
              @click.stop="
                notifications.sale.record4 =
                  notifications.sale.record4 == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.sale.records_perso") }}
            <span
              :class="['circle', { clicked: notifications.sale.record1 == 1 }]"
              @click.stop="
                notifications.sale.record1 =
                  notifications.sale.record1 == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
        <!-- <div class="bloc-notif"> 
        
      </div>-->
        <div class="bloc-notif">
          <h2>
            {{
              td(
                "views.params.params_notifications.overtaking.title.overtaking"
              )
            }}
          </h2>
          <div>
            {{ td("views.params.params_notifications.overtaking.congrats") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.overtaking_win == 1 }
              ]"
              @click.stop="
                notifications.sale.overtaking_win =
                  notifications.sale.overtaking_win == 1 ? 0 : 1
              "
            ></span>
          </div>
          <div>
            {{ td("views.params.params_notifications.overtaking.too_bad") }}
            <span
              :class="[
                'circle',
                { clicked: notifications.sale.overtaking_lost == 1 }
              ]"
              @click.stop="
                notifications.sale.overtaking_lost =
                  notifications.sale.overtaking_lost == 1 ? 0 : 1
              "
            ></span>
          </div>
        </div>
      </div>
      <div class="column-notifications" v-if="$store.getters.adminValue < 8">
        <h2
          v-html="
            td('views.params.params_notifications.sales_notifications.title')
          "
        >
          <span
            :class="['circle-empty', { 'circle-grey': getClickAllSale() }]"
          ></span>
        </h2>
        <h3
          v-html="
            td(
              'views.params.params_notifications.sales_notifications.selected_settings'
            )
          "
        ></h3>
        <div class="bloc-notif" style="min-height:206px">
          <h2>
            {{
              td(
                "views.params.params_notifications.sales_notifications.objectif"
              )
            }}
          </h2>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.objectif_half"
              )
            }}<span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.objectif2 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.objectif_75pct"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.objectif3 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.objectif_all"
              )
            }}<span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.objectif4 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.new_objectif"
              )
            }}<span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.objectif1 == 1 }
              ]"
            ></span>
          </div>
        </div>
        <div class="bloc-notif" style="min-height:178px">
          <h2>
            {{
              td(
                "views.params.params_notifications.sales_notifications.challenges"
              )
            }}
          </h2>
          <div
            v-html="
              td(
                'views.params.params_notifications.sales_notifications.new_challenges'
              )
            "
          >
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.challenges_new == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.challenge_won"
              )
            }}<span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.challenges_won == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.challenge_lost"
              )
            }}<span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.challenges_lost == 1 }
              ]"
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>
            {{
              td("views.params.params_notifications.sales_notifications.record")
            }}
          </h2>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.record_team"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.record2 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.record_group"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.record3 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.record_company"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.record4 == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.record_perso"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.record1 == 1 }
              ]"
            ></span>
          </div>
        </div>
        <div class="bloc-notif">
          <h2>
            {{
              td(
                "views.params.params_notifications.sales_notifications.overtaking"
              )
            }}
          </h2>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.overtaking.congrats"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.overtaking_win == 1 }
              ]"
            ></span>
          </div>
          <div>
            {{
              td(
                "views.params.params_notifications.sales_notifications.overtaking.too_bad"
              )
            }}
            <span
              :class="[
                'circle-empty',
                { 'circle-grey': notifications.sale.overtaking_lost == 1 }
              ]"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="notifications-footer" @click.stop="valideHandler">
      <button class="">
        {{ td("views.params.params_notifications.cta.valide") }}
      </button>
      <button class="negatif" @click.stop="cancelHandler">
        {{ td("views.params.params_notifications.cta.cancel") }}
      </button>
    </div>
    <transition name="fade">
      <div
        class="container-alert"
        style="height:100%"
        v-if="show_alert_box === true"
      >
        <alertBox
          :msg="msg_alert"
          :callback="alertCallback"
          :delay="alert_delay"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import { sha256 } from "js-sha256";
import alertBox from "./../../components/alertBoxes/alertBox";
import { td } from "../../traductions/traductionsHelpers";
export default {
  name: "ParamsNotifications",
  components: { alertBox },
  data: () => {
    return {
      td,
      group_profils: [],
      team_profils: [],
      //alert box
      show_alert_box: false,
      msg_alert: td("views.params.params_notifications.msg_alert"),
      alert_delay: 3000,
      admin_levels: {},
      user: {},
      admin_level: "",
      circle: {},
      functions: [],
      plus_clicked: false,
      editable: "",
      functions_loaded: false,
      used_functions: [],
      notifications: {
        ceo_manager: {
          objectif1: 0,
          objectif2: 0,
          objectif3: 0,
          objectif4: 0,
          challenges_name: 0,
          /*challenges_new: 0,
          challenges_won: 0,
          challenges_lost: 0,*/
          record1: 0,
          record2: 0,
          record3: 0,
          record4: 0,
          podium1: 0,
          podium2: 0,
          podium3: 0,
          podium4: 0
        },
        group_manager: {
          objectif1: 0,
          objectif2: 0,
          objectif3: 0,
          objectif4: 0,
          challenges_name: 0,
          challenges_new: 0,
          challenges_won: 0,
          challenges_lost: 0,
          record1: 0,
          record2: 0,
          record3: 0,
          record4: 0,
          podium1: 0,
          podium2: 0,
          podium3: 0,
          podium4: 0
        },
        team_manager: {
          objectif1: 0,
          objectif2: 0,
          objectif3: 0,
          objectif4: 0,
          challenges_name: 0,
          challenges_new: 0,
          challenges_won: 0,
          challenges_lost: 0,
          record1: 0,
          record2: 0,
          record3: 0,
          record4: 0,
          podium1: 0,
          podium2: 0,
          podium3: 0,
          podium4: 0
        },
        sale: {
          objectif1: 0,
          objectif2: 0,
          objectif3: 0,
          objectif4: 0,
          // challenges_name: 0,
          challenges_new: 0,
          challenges_won: 0,
          challenges_lost: 0,
          record1: 0,
          record2: 0,
          record3: 0,
          record4: 0,
          podium1: 0,
          podium2: 0,
          podium3: 0,
          podium4: 0,
          overtaking_win: 0,
          overtaking_lost: 0
        }
      }
    };
  },
  methods: {
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    valideHandler() {
      this.saveNotifications();
    },
    cancelHandler() {
      this.$router.go(-1);
    },
    clickAllCeo() {
      if (this.getClickAllCeo() == true) {
        for (const key in this.notifications.ceo_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.ceo_manager.hasOwnProperty(key)) {
            this.notifications.ceo_manager[key] = 0;
          }
        }
      } else {
        for (const key in this.notifications.ceo_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.ceo_manager.hasOwnProperty(key)) {
            this.notifications.ceo_manager[key] = 1;
          }
        }
      }
    },
    getClickAllCeo() {
      // eslint-disable-next-line no-unreachable
      for (const key in this.notifications.ceo_manager) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.notifications.ceo_manager.hasOwnProperty(key)) {
          const element = this.notifications.ceo_manager[key];
          if (element == 0) {
            return false;
          }
        }
      }
      return true;
    },
    clickAllGroup() {
      if (this.getClickAllGroup() == true) {
        for (const key in this.notifications.group_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.group_manager.hasOwnProperty(key)) {
            this.notifications.group_manager[key] = 0;
          }
        }
      } else {
        for (const key in this.notifications.group_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.group_manager.hasOwnProperty(key)) {
            this.notifications.group_manager[key] = 1;
          }
        }
      }
    },
    getClickAllGroup() {
      // eslint-disable-next-line no-unreachable
      for (const key in this.notifications.group_manager) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.notifications.group_manager.hasOwnProperty(key)) {
          const element = this.notifications.group_manager[key];
          if (element == 0) {
            return false;
          }
        }
      }
      return true;
    },
    clickAllTeam() {
      if (this.getClickAllTeam() == true) {
        for (const key in this.notifications.team_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.team_manager.hasOwnProperty(key)) {
            this.notifications.team_manager[key] = 0;
          }
        }
      } else {
        for (const key in this.notifications.team_manager) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.team_manager.hasOwnProperty(key)) {
            this.notifications.team_manager[key] = 1;
          }
        }
      }
    },
    getClickAllTeam() {
      // eslint-disable-next-line no-unreachable
      for (const key in this.notifications.team_manager) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.notifications.team_manager.hasOwnProperty(key)) {
          const element = this.notifications.team_manager[key];
          if (element == 0) {
            return false;
          }
        }
      }
      return true;
    },
    clickAllSale() {
      if (this.getClickAllSale() == true) {
        for (const key in this.notifications.sale) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.sale.hasOwnProperty(key)) {
            this.notifications.sale[key] = 0;
          }
        }
      } else {
        for (const key in this.notifications.sale) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.notifications.sale.hasOwnProperty(key)) {
            this.notifications.sale[key] = 1;
          }
        }
      }
    },
    getClickAllSale() {
      // eslint-disable-next-line no-unreachable
      for (const key in this.notifications.sale) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.notifications.sale.hasOwnProperty(key)) {
          const element = this.notifications.sale[key];
          if (element == 0) {
            return false;
          }
        }
      }
      return true;
    },
    getProfils() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = {
        circle_token: this.circle.circle_token,
        email: this.user.email
      };

      let sendData = { credentials, filters };

      axios
        .post("/users/by", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);

            let users = data.response.users;
            for (let i = 0; i < users.length; i++) {
              if (users[i].admin_level == "group_manager") {
                context.group_profils.push(users[i].user_token);
              }
              if (users[i].admin_level == "team_manager") {
                context.team_profils.push(users[i].user_token);
              }
            }
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNotifications() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let filters = { circle_token: this.circle.circle_token };

      let sendData = {
        credentials,
        notifications: this.notifications,
        filters
      };

      axios
        .post("/circles/update/notifications", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            store.commit("authUser", authData);
            context.show_alert_box = true;
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;
    this.circle = this.$store.state.circleData;
    this.functions = this.circle.params.functions;

    if (this.user.notifications) {
      this.notifications = this.user.notifications;
    }

    if (this.circle.params.notifications) {
      if (this.circle.params.notifications.sale) {
        this.notifications.sale = this.circle.params.notifications.sale;
      }
    }

    this.getProfils();
  }
};
</script>
