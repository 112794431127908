import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vSelect from "vue-select";
import VCalendar from "v-calendar";
import { config } from "./config/config.js";
import { getTraductionFiles } from "./../src/traductions/traductionsHelpers";

//axios.defaults.baseURL = "http://localhost:3005"; //local dev
//axios.defaults.baseURL = "https://api.dev.karamba.opp.mx"; // prod

console.log("=== config.baseURL === main.js === key: 228273 ===");
console.log(config.baseURL());
console.log(process.env.BASE_URL);
console.log("=================================");

axios.defaults.baseURL = config.baseURL();

const init = async () => {
  await getTraductionFiles();

  global.glob = 1;

  Vue.component("v-select", vSelect);
  Vue.directive("click-outside", {
    bind: function(el, binding, vnode) {
      el.clickOutsideEvent = function(event) {
        // here I check that click was outside the el and his childrens
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind: function(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    }
  });
  Vue.use(VCalendar, {
    componentPrefix: "v" // Use <vc-calendar /> instead of <v-calendar />              // ...other defaults
  });

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
};

init();
