var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-content"},[(_vm.functions_loaded)?_c('div',{staticClass:"column-functions"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("views.params.params_functions.title.functions"))+" "),(_vm.$store.getters.adminValue > 4)?_c('span',{staticClass:"plus",on:{"click":function($event){$event.stopPropagation();return _vm.clickHandler({ type: 'click-plus' })}}},[_vm._v("+")]):_vm._e()]),_vm._l((_vm.functions),function(item,index){return _c('div',{key:index,staticClass:"function-item"},[_c('p',[_vm._v(_vm._s(item))]),_c('div',{class:['pen', { empty: _vm.$store.getters.adminValue < 8 }],on:{"click":function($event){$event.stopPropagation();return _vm.editFunction(item)}}}),_c('div',{class:[
          'cross',
          {
            empty: _vm.getEmptyCross(item)
          }
        ],on:{"click":function($event){$event.stopPropagation();return _vm.deleteFunction(item)}}})])}),(_vm.plus_clicked)?_c('div',{staticClass:"overlay"}):_vm._e()],2):_vm._e(),(_vm.plus_clicked)?_c('div',{staticClass:"new-function"},[_c('h2',[_vm._v(" "+_vm._s(_vm.td("views.params.params_functions.add_function"))+" ")]),_c('div',{staticClass:"bloc-content-circle set-position-relative"},[_c('h3',[_vm._v(_vm._s(_vm.td("views.params.params_functions.function_name")))]),(_vm.editable == 'name')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_function.name),expression:"new_function.name"}],ref:"name",staticClass:"p",attrs:{"type":"text"},domProps:{"value":(_vm.new_function.name)},on:{"blur":function($event){_vm.editable = '';
          _vm.testEmptyContent('name');},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.new_function, "name", $event.target.value)}}}):_c('p',{staticClass:"hoverable",attrs:{"data-hover":_vm.td('views.params.params_functions.data_hover.name')},on:{"click":function($event){_vm.setPreviousContent('name');
          _vm.editable = 'name';}}},[_vm._v(" "+_vm._s(_vm.new_function.name)+" ")]),_c('div',{staticClass:"footer-content"},[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.saveNewFunction.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.td("views.params.params_functions.valide"))+" ")]),_c('button',{staticClass:"negatif",on:{"click":function($event){$event.stopPropagation();return _vm.cancelHandler.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.td("views.params.params_functions.cancel"))+" ")])])])]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show_confirm_box === true)?_c('div',{staticClass:"container-alert"},[_c('confirmBox',{attrs:{"msg1":_vm.msg_confirm1,"msg2":_vm.msg_confirm2,"title":_vm.title_confirm,"callback":_vm.confirmCallback,"delay":_vm.confirm_delay}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }