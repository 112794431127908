<template>
  <main>
    <div class="wrapper circles-like new-user">
      <section class="large-content top-bar">
        <div class="large-content-column">
          <h2>{{ td("views.teams.new_user.select_account") }}</h2>
        </div>
        <div class="large-content-column col-3">
          <div class="bloc-search">
            <h2>{{ td("views.teams.new_user.search") }}</h2>
            <div class="bottom-line">
              <v-select
                label="name"
                v-if="editable == 'search'"
                :placeholder="td('views.teams.new_user.name.placeholder')"
                :options="new_user_options"
                :components="{ OpenIndicator }"
                v-model="new_user_selected"
                @input="
                  editable = '';
                  setNewUserFromVSelect();
                "
              >
                <div slot="no-options">
                  {{ td("views.teams.new_user.sorry_but") }}
                </div>
              </v-select>

              <p
                class="hoverable"
                :data-hover="td('views.teams.new_user.name.placeholder')"
                v-else
                @click="editable = 'search'"
              >
                {{ getSelectedUserName() }}
              </p>
              <img src="/assets/img/loupe.png" alt="" />
            </div>
          </div>
          <!-- <div class="bloc-buttons ">
              <button class="" @click="valideSelectedCircle">
                Valider
              </button>
              <button
                class="negatif"
                @click="
                  circle_selected = null;
                  circle = circle_init;
                "
              >
                Annuler
              </button>
            </div>-->
        </div>
      </section>
      <section class="large-content circle-body ">
        <div class="large-content-column">
          <div class="body-content set-position-relative">
            <div class="bloc-content-profil">
              <h2 v-if="profil_type == 'ceo_manager'">
                {{ td("views.teams.new_user.new_ceo") }}
              </h2>
              <h2 v-if="profil_type == 'group_manager'">
                {{ td("views.teams.new_user.new_resp") }}
              </h2>
              <h2 v-if="profil_type == 'team_manager'">
                {{ td("views.teams.new_user.new_tl") }}
              </h2>
              <h2 v-if="profil_type == 'sale'">
                {{ td("views.teams.new_user.new_sale") }}
              </h2>
            </div>
            <div class="bloc-content-profil new-user-image ">
              <img
                :src="getUserLogo()"
                alt=""
                class="header-img-profil"
                id="user_image"
              />
              <img
                src="/assets/img/change_img_profil_overlay.png"
                alt=""
                class="header-img-profil overlay"
                @click.prevent="changePhotoHandler"
                v-if="$store.getters.adminValue > 8"
              />
              <input
                style="display:none"
                type="file"
                name="file_01"
                id="file_01"
                ref="file_01"
                :accept="files_allowed"
                class="form-control fa-file-photo-o "
                @change="changeFileUplaod('file_01')"
              />
            </div>
            <div :class="{ 'overlay-block': new_user.user_token }"></div>
          </div>
          <div class="footer-content">
            <button
              class=""
              style="width:165px;height:42px"
              @click="saveNewUser"
              v-if="profil_type == 'ceo_manager'"
              v-html="td('views.teams.new_user.add_ceo')"
            ></button>
            <button
              class=""
              style="min-width:165px;width:165px;height:42px"
              @click="saveNewGroup"
              v-if="profil_type == 'group_manager'"
              v-html="td('views.teams.new_user.add_resp')"
            ></button>
            <button
              class=""
              style="width:165px;height:42px"
              @click="saveNewTeam"
              v-if="profil_type == 'team_manager'"
              v-html="td('views.teams.new_user.add_tl')"
            ></button>
            <button
              class=""
              style="width:165px;height:42px"
              @click="saveNewUser"
              v-if="profil_type == 'sale'"
              v-html="td('views.teams.new_user.add_sale')"
            ></button>
            <button
              class="negatif"
              style="width:105px;height:42px"
              @click="cancelHandler"
            >
              Annuler
            </button>
          </div>
        </div>
        <div class="large-content-column col-3">
          <div class="wrapper">
            <div class="new-user-bottom">
              <div class="column-content-circle">
                <div class="bloc-content-circle   set-position-relative">
                  <h3>{{ td("views.teams.new_user.gender") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.new_user.gender.m") }}
                      <input
                        type="radio"
                        id="m"
                        value="m"
                        v-model="new_user.gender"
                        name="m"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.new_user.gender.f") }}
                      <input
                        type="radio"
                        id="f"
                        value="f"
                        v-model="new_user.gender"
                        name="f"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.name") }}</h3>
                  <input
                    v-if="editable == 'lastname'"
                    ref="lastname"
                    type="text"
                    v-model="new_user.lastname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('lastname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_user.name.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('lastname');
                      editable = 'lastname';
                    "
                  >
                    {{ new_user.lastname }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.email") }}</h3>
                  <input
                    v-if="editable == 'email'"
                    ref="email"
                    type="text"
                    v-model="new_user.email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_user.email.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('email');
                      editable = 'email';
                    "
                  >
                    {{ new_user.email }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div
                  class="bloc-content-circle for-drop-down"
                  v-if="profil_type == 'group_manager'"
                >
                  <h3>{{ td("views.teams.new_user.select_group") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_group_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_group_clicked = !drop_down_group_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        new_group_selected.name ||
                          td("views.teams.new_user.group_name")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_group_clicked = !drop_down_group_clicked
                      "
                    >
                      {{
                        new_group_selected.name ||
                          td("views.teams.new_user.group_name")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in new_group_options"
                      :key="key"
                      @click="
                        drop_down_group_clicked = false;
                        new_group_selected = { ...option };
                        new_group = { ...option };
                        is_new_group = false;
                        setPreviousContent('');
                      "
                    >
                      {{ option.name }}
                    </h3>
                    <h3
                      class="last"
                      @click="
                        is_new_group = true;
                        drop_down_group_clicked = false;
                        new_group_selected = {};
                        new_group = {};
                        new_group = { ...new_group_init };
                      "
                    >
                      <span> <img src="/assets/img/plus.png" alt="" /> </span
                      >{{ td("views.teams.new_user.group") }}
                    </h3>
                  </div>
                </div>
                <div
                  class="bloc-content-circle for-drop-down"
                  v-else-if="profil_type == 'team_manager'"
                >
                  <h3>{{ td("views.teams.new_user.select_team") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_team_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_team_clicked = !drop_down_team_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        new_team_selected.name ||
                          td("views.teams.new_user.team_name")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_team_clicked = !drop_down_team_clicked"
                    >
                      {{
                        new_team_selected.name ||
                          td("views.teams.new_user.team_name")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in new_team_options"
                      :key="key"
                      @click="
                        drop_down_team_clicked = false;
                        new_team_selected = { ...option };
                        new_team = { ...option };
                        is_new_team = false;
                        setPreviousContent('');
                      "
                    >
                      {{ option.name }}
                    </h3>
                    <h3
                      class="last"
                      @click="
                        is_new_team = true;
                        drop_down_team_clicked = false;
                        new_team_selected = {};
                        new_team = {};
                        new_team = { ...new_team_init };
                      "
                    >
                      <span> <img src="/assets/img/plus.png" alt="" /> </span
                      >{{ td("views.teams.new_user.team") }}
                    </h3>
                  </div>
                </div>
                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle" />
              </div>
              <div class="column-content-circle">
                <div
                  class="bloc-content-circle set-position-relative"
                  v-if="!new_user.user_token"
                >
                  <h3>{{ td("views.teams.new_user.send_password") }}</h3>
                  <div class="container-radio">
                    <label class="container"
                      >{{ td("views.teams.new_user.yes") }}
                      <input
                        type="radio"
                        id="mdp1"
                        value="1"
                        v-model="send_password"
                        name="mdp1"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark "></span>
                    </label>
                    <label class="container"
                      >{{ td("views.teams.new_user.no") }}
                      <input
                        type="radio"
                        id="mdp0"
                        value="0"
                        v-model="send_password"
                        name="mdp0"
                        @input="
                          setPreviousContent('');
                          editable = '';
                        "
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.firstname") }}</h3>
                  <input
                    v-if="editable == 'firstname'"
                    ref="firstname"
                    type="text"
                    v-model="new_user.firstname"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('firstname');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_user.firstname.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('firstname');
                      editable = 'firstname';
                    "
                  >
                    {{ new_user.firstname }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.confirm_email") }}</h3>
                  <input
                    v-if="editable == 'confirm_email'"
                    ref="confirm_email"
                    type="text"
                    v-model="new_user.confirm_email"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('confirm_email');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_user.confirm_email.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('confirm_email');
                      editable = 'confirm_email';
                    "
                  >
                    {{ new_user.confirm_email }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle" v-if="is_new_group">
                  <h3>{{ td("views.teams.new_user.new_group") }}</h3>
                  <input
                    v-if="editable == 'new_group_name'"
                    ref="new_group_name"
                    type="text"
                    v-model="new_group.name"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContentGroup('new_group_name');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_user.new_group.placeholder')
                    "
                    v-else
                    @click="
                      ContentGroup('new_group_name');
                      editable = 'new_group_name';
                    "
                  >
                    {{ new_group.name }}
                  </p>
                </div>
                <div class="bloc-content-circle" v-else-if="is_new_team">
                  <h3>{{ td("views.teams.new_user.new_team") }}</h3>
                  <input
                    v-if="editable == 'new_team_name'"
                    ref="new_team_name"
                    type="text"
                    v-model="new_team.name"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContentTeam('new_team_name');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_user.new_team.placeholder')
                    "
                    v-else
                    @click="
                      ContentTeam('new_team_name');
                      editable = 'new_team_name';
                    "
                  >
                    {{ new_team.name }}
                  </p>
                </div>

                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle" />
              </div>
              <div class="column-content-circle">
                <div class="bloc-content-circle for-drop-down">
                  <div
                    class="drop-down closed"
                    v-if="drop_down_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_clicked = !drop_down_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.new_user.language")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down opened" v-else>
                    <h3
                      class="pointer"
                      @click="drop_down_clicked = !drop_down_clicked"
                    >
                      {{
                        languages_options[new_user.language] ||
                          td("views.teams.new_user.language")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(value, key) in languages_options"
                      :key="key"
                      @click="
                        drop_down_clicked = false;
                        new_user.language = key;
                        setPreviousContent('');
                      "
                    >
                      {{ value }}
                    </h3>
                  </div>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.function") }} ertgertg</h3>
                  <input
                    v-if="editable == 'function'"
                    ref="function"
                    type="text"
                    v-model="new_user.function"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('function');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="
                      td('views.teams.new_user.function.placeholder')
                    "
                    v-else
                    @click="
                      setPreviousContent('function');
                      editable = 'function';
                    "
                  >
                    {{ new_user.function }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div class="bloc-content-circle set-position-relative">
                  <h3>{{ td("views.teams.new_user.phone") }}</h3>
                  <input
                    v-if="editable == 'phone'"
                    ref="phone"
                    type="text"
                    v-model="new_user.phone"
                    class="p"
                    @blur="
                      editable = '';
                      testEmptyContent('phone');
                    "
                  />
                  <p
                    class="hoverable"
                    :data-hover="td('views.teams.new_user.phone.placeholder')"
                    v-else
                    @click="
                      setPreviousContent('phone');
                      editable = 'phone';
                    "
                  >
                    {{ new_user.phone }}
                  </p>
                  <div :class="{ 'overlay-block': new_user.user_token }"></div>
                </div>
                <div
                  class="bloc-content-circle for-drop-down"
                  v-if="profil_type == 'team_manager' && is_new_team == true"
                >
                  <h3>{{ td("views.teams.new_user.objectif_period") }}</h3>
                  <div
                    class="drop-down-group closed"
                    v-if="drop_down_objectifs_clicked == false"
                  >
                    <h3
                      class="pointer"
                      @click="
                        drop_down_objectifs_clicked = !drop_down_objectifs_clicked;
                        setPreviousContent('');
                      "
                    >
                      {{
                        new_objectifs_selected.name ||
                          td("views.teams.new_user.period")
                      }}<span
                        ><img src="/assets/img/arrow_down_black.png" alt="" />
                      </span>
                    </h3>
                  </div>
                  <div class="drop-down-group opened" v-else>
                    <h3
                      class="pointer"
                      @click="
                        drop_down_objectifs_clicked = !drop_down_objectifs_clicked
                      "
                    >
                      {{
                        new_objectifs_selected.name ||
                          td("views.teams.new_user.period")
                      }}<span>
                        <img src="/assets/img/arrow_up_black.png" alt="" />
                      </span>
                    </h3>
                    <h3
                      v-for="(option, key) in new_objectifs_options"
                      :key="key"
                      @click="
                        drop_down_objectifs_clicked = false;
                        new_objectifs_selected = { ...option };
                        setPreviousContent('');
                      "
                    >
                      {{ option.name }}
                    </h3>
                  </div>
                </div>
                <div
                  class="bloc-content-circle"
                  v-else-if="profil_type == 'team_manager'"
                >
                  <h3>{{ td("views.teams.new_user.objectif_period") }}</h3>
                  <p class="">
                    {{ getPeriod(new_team_selected.objectifs_period) }}
                  </p>
                </div>
                <div class="bloc-content-circle" v-else />
                <div class="bloc-content-circle" />
              </div>
            </div>
          </div>
        </div>
        <transition name="fade">
          <div class="container-change-password" v-if="show_alert_box === true">
            <alertBox
              :msg="msg_alert"
              :callback="alertCallback"
              :delay="3000"
            />
          </div>
        </transition>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import alertBox from "./../../components/alertBoxes/alertBox";
import axios from "axios";
import "vue-select/dist/vue-select.css";
import { sha256 } from "js-sha256";

// eslint-disable-next-line no-unused-vars
import { valideUser } from "./../../assets/scripts/users";
import { td } from "../../traductions/traductionsHelpers";

export default {
  name: "NewUser",
  components: {
    alertBox
  },
  data: () => {
    return {
      td,
      group_token: "",
      profil_type: "",
      send_password: 0,
      OpenIndicator: {
        render: createElement => {
          let elem = createElement("span", { class: [{ toggle: true }] });
          return elem;
        }
      },
      editable: "",
      drop_down_clicked: false,
      //group
      drop_down_group_clicked: false,
      new_group_options: [
        {
          name: "Région sud",
          group_token: "ertg321ergt"
        }
      ],
      new_group_selected: {},
      is_new_group: false,
      new_group: {
        name: td("views.teams.new_user.new_group.name")
      },
      new_group_init: {
        name: td("views.teams.new_user.new_group_init.name")
      },
      //team
      drop_down_team_clicked: false,
      new_team_options: [
        {
          name: td("views.teams.new_user.new_team_options.name"),
          team_token: "ertg321ergt"
        }
      ],
      new_team_selected: {},
      is_new_team: false,
      new_team: {
        name: td("views.teams.new_user.new_team.name")
      },
      new_team_init: {
        name: td("views.teams.new_user.new_team_init.name")
      },
      //objectifs
      drop_down_objectifs_clicked: false,
      new_objectifs_options: [],
      new_objectifs_selected: {},
      //
      new_users_options: [],
      new_user_selected: {},
      languages_options: {
        fr: "fr",
        nl: "nl",
        en: "en"
      },
      files_allowed: "image/png, image/jpeg, image/jpg",
      file_01: null,
      msg_alert: "",
      show_alert_box: false,
      admin_levels: {},
      user: {}, //le user qui est connecté, pas celui que l'on crée
      new_user_init: {
        name: td("views.teams.new_user.new_user_init.name"),
        firstname: td("views.teams.new_user.new_user_init.firstname"),
        lastname: td("views.teams.new_user.new_user_init.lastname"),
        email: td("views.teams.new_user.new_user_init.email"),
        confirm_email: td("views.teams.new_user.new_user_init.confirm_email"),
        function: td("views.teams.new_user.new_user_init.function"),
        phone: td("views.teams.new_user.new_user_init.phone"),
        gender: td("views.teams.new_user.new_user_init.gender"),
        language: td("views.teams.new_user.new_user_init.language")
      },
      new_user: {},
      admin_level: "",
      previousContent: {
        new_user: {},
        new_group: {},
        new_team: {}
      }
    };
  },
  methods: {
    getPeriod(key) {
      if (key == "week") {
        return td("views.teams.new_user.week");
      }
      if (key == "month") {
        return td("views.teams.new_user.month");
      }
      if (key == "trim") {
        return td("views.teams.new_user.trim");
      }
      if (key == "quadri") {
        return td("views.teams.new_user.quadri");
      }
      if (key == "sem") {
        return td("views.teams.new_user.semester");
      }
      if (key == "year") {
        return td("views.teams.new_user.year");
      }
      return key;
    },
    setNewUserFromVSelect() {
      if (this.new_user_selected === null) {
        this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
        this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));
        return;
      }
      this.new_user = JSON.parse(JSON.stringify(this.new_user_selected));
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
    },
    cancelHandler() {
      this.file_01 = null;
      this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
      this.editable = "";
      this.send_password = 0;

      this.new_team_selected = {};
      this.is_new_team = false;
      this.new_team = this.new_team_init;
      this.drop_down_team_clicked = false;

      this.new_group_selected = {};
      this.is_new_group = false;
      this.new_group = this.new_group_init;
      this.drop_down_group_clicked = false;

      this.drop_down_objectifs_clicked = false;
      this.new_objectifs_selected = {};
      var output = document.getElementById("user_image");
      output.src = this.getUserLogo();
      setTimeout(() => {
        this.$router.go(-1);
      }, 100);
    },
    setPreviousContent(key) {
      if (this.new_user.user_token) {
        // this.cancelHandler();
        // return;
      }
      this.previousContent.new_user[key] = this.new_user[key];
      if (this.new_user[key] == this.new_user_init[key]) {
        this.new_user[key] = "";
      }
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContent(key) {
      if (this.new_user[key].trim() == "") {
        this.new_user[key] = this.previousContent.new_user[key];
      }
    },
    ContentGroup(key) {
      this.previousContent.new_group.name = this.new_group.name;
      this.new_group.name = "";
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContentGroup(key) {
      key;
      if (this.new_team.name.trim() == "") {
        this.new_team.name = this.previousContent.new_team.name;
      }
    },
    ContentTeam(key) {
      this.previousContent.new_team.name = this.new_team.name;
      this.new_team.name = "";
      setTimeout(() => {
        if (this.$refs[key]) {
          this.$refs[key].focus();
        }
      }, 250);
    },
    testEmptyContentTeam(key) {
      key;
      if (this.new_team.name.trim() == "") {
        this.new_team.name = this.previousContent.new_team.name;
      }
    },
    changeFileUplaod(key) {
      this.file_01 = this.$refs[key].files[0];
      var reader = new FileReader();
      reader.onload = function() {
        var output = document.getElementById("user_image");
        output.src = reader.result;
      };
      reader.readAsDataURL(this.file_01);
    },
    changePhotoHandler() {
      document.querySelector("#file_01").click();
    },
    getUserLogo() {
      if (this.new_user.picture) {
        if (this.new_user.picture.trim() == "") {
          return "/assets/img/profil2.png";
        }
        return axios.defaults.baseURL + "/images/" + this.new_user.picture;
      }
      return "/assets/img/profil2.png";
    },
    getSelectedUserName() {
      if (!this.new_user) {
        return td("views.teams.new_user.get_selected_username");
      }
      if (!this.new_user.user_token) {
        return td("views.teams.new_user.get_selected_username");
      }
      if (this.new_user) {
        return this.new_user.firstname + " " + this.new_user.lastname;
      }
      return td("views.teams.new_user.get_selected_username");
    },
    alertCallback(obj) {
      if (obj.type == "end_delay") {
        this.show_alert_box = false;
      }
    },
    headerCallbackHandler(obj) {
      obj;
    },
    getUsers() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = { circle_token: this.$store.state.circleToken };
      let sendData = { credentials, filters };

      axios
        .post("/users/list", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /users/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            let scratchArray = data.response.users.map(obj => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });

            let reduced = Array.from(
              new Set(scratchArray.map(a => a.email))
            ).map(email => {
              return scratchArray.find(a => a.email === email);
            });

            let finalArray = [];
            if (context.profil_type == "ceo_manager") {
              for (let i = 0; i < reduced.length; i++) {
                if (reduced[i].admin_level != "ceo_manager") {
                  finalArray.push(reduced[i]);
                }
              }
            } else {
              for (let i = 0; i < reduced.length; i++) {
                finalArray.push(reduced[i]);
              }
            }

            context.new_user_options = finalArray;
            store.commit("authUser", authData);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getGroups() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = { circle_token: this.$store.state.circleToken };
      let sendData = { credentials, filters };

      axios
        .post("/groups/list", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /groups/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            /*let scratchArray = data.response.users.map((obj) => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });*/

            let groups = data.response.groups;

            /*let reduced = Array.from(
              new Set(groups.map((a) => a.group_token))
            ).map((group_token) => {
              return groups.find((a) => a.group_token === group_token);
            });*/

            context.new_group_options = groups;
            store.commit("authUser", authData);
            context.getUsers();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    getTeams() {
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }
      let filters = {
        group_token: this.group_token,
        circle_token: this.$store.state.circleToken
      };
      let sendData = { credentials, filters };

      axios
        .post("/teams/by", sendData)
        .then(function(result) {
          let data = result.data;

          ("=========== /teams/list ====== let data = result.data; ===================");

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            /*let scratchArray = data.response.users.map((obj) => {
              let newObj = { ...obj };
              newObj.function = obj.functions[0];
              newObj.name = obj.firstname + " " + obj.lastname;
              return newObj;
            });*/

            let teams = data.response.teams;

            /*let reduced = Array.from(
              new Set(groups.map((a) => a.group_token))
            ).map((group_token) => {
              return groups.find((a) => a.group_token === group_token);
            });*/

            context.new_team_options = teams;
            store.commit("authUser", authData);
            context.getUsers();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNewGroup() {
      if (this.new_group_selected.group_token) {
        this.saveNewUser();
        return;
      }

      if (this.new_group.name == this.new_group_init.name) {
        this.msg_alert = td("views.teams.new_user.msg_alert");
        this.show_alert_box = true;
        return;
      }

      let err = valideUser(this.new_user, this.new_user_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }

      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let group = this.new_group;
      group.circle_token = this.$store.state.circleToken;

      let sendData = { credentials, group };

      axios
        .post("/groups/new", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.new_group = data.response.group;
            context.new_user.group_token = context.new_group.group_token;
            context.new_user.group_name = context.new_group.name;
            store.commit("authUser", authData);
            context.saveNewUser();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNewTeam() {
      if (this.new_team_selected.team_token) {
        this.saveNewUser();
        return;
      }

      if (this.new_team.name == this.new_team_init.name) {
        this.msg_alert = td("views.teams.new_user.msg_alert2");

        this.show_alert_box = true;
        return;
      }
      if (!this.new_objectifs_selected.value) {
        this.msg_alert = td("views.teams.new_user.msg_alert3");
        this.show_alert_box = true;
        return;
      }

      let err = valideUser(this.new_user, this.new_user_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }

      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let team = this.new_team;
      team.circle_token = this.$store.state.circleToken;
      team.group_token = this.group_token;
      team.objectifs_period = this.new_objectifs_selected.value;

      let sendData = { credentials, team };

      // return;
      // eslint-disable-next-line no-unreachable
      axios
        .post("/teams/new", sendData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.new_team = data.response.team;
            store.commit("authUser", authData);
            context.saveNewUser();
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    },
    saveNewUser() {
      if (this.profil_type == "ceo_manager") {
        let context = this;
        let store = this.$store;
        let router = this.$router;
        context;
        router;
        store;

        let credentials = {};
        if (this.$store.getters.isConnected) {
          credentials = this.$store.getters.credentials;
        }

        let sendData = { credentials };

        let filters = {
          email: this.new_user.email,
          circle_token: this.new_user.circle_token
        };

        sendData.filters = filters;

        axios
          .post("/users/by", sendData)
          .then(function(result) {
            let data = result.data;

            if (data.response.success === true) {
              const authData = {
                tokenId: data.tokenId,
                userToken: data.user_context.user.user_token,
                userData: data.user_context.user
              };

              store.commit("authUser", authData);

              if (data.response.users.length) {
                if (context.admin_level == "admin") {
                  context.doSaveNewUser();
                  return;
                }

                //context.msg_alert = td("views.teams.new_user.msg_alert4");
                //context.show_alert_box = true;
                if (context.profil_type == "ceo_manager") {
                  context.doSaveNewUser();
                } else {
                  context.msg_alert = td("views.teams.new_user.msg_alert4");
                  context.show_alert_box = true;
                }

                return;
              } else {
                context.doSaveNewUser();
              }
            } else {
              store.dispatch("logout");
              router.replace("/login");
            }
          })
          .catch(function(error) {
            error;
          });
        return;
      } else {
        this.doSaveNewUser();
      }
    },
    doSaveNewUser() {
      // eslint-disable-next-line no-unreachable
      let context = this;
      let store = this.$store;
      let router = this.$router;
      context;
      router;
      store;

      let err = valideUser(this.new_user, this.new_user_init);

      if (err.trim() != "") {
        this.msg_alert = err;
        this.show_alert_box = true;
        return;
      }

      // eslint-disable-next-line no-unreachable
      let credentials = {};
      if (this.$store.getters.isConnected) {
        credentials = this.$store.getters.credentials;
      }

      sha256;

      let url = "/users/new/ceo";

      this.new_user.admin_level = this.profil_type;
      this.new_user.circle_token = this.$store.state.circleToken;
      this.new_user.circle_name = this.$store.state.circleData.name;

      if (this.profil_type == "group_manager") {
        this.new_user.group_name = this.new_group.name;
        if (this.new_group.group_token) {
          this.new_user.group_token = this.new_group.group_token;
        }

        url = "/users/new/group";
      }
      if (this.profil_type == "team_manager") {
        this.new_user.team_name = this.new_team.name;
        if (this.group_token != "") {
          this.new_user.group_token = this.group_token;
        }

        if (this.new_team.team_token) {
          this.new_user.team_token = this.new_team.team_token;
        }

        url = "/users/new/team";
      }
      this.new_user.functions = [this.new_user.function];
      this.new_user.send_password = this.send_password;
      delete this.new_user.confirm_email;
      delete this.new_user.function;
      delete this.new_user.name;

      //return;

      // eslint-disable-next-line no-unreachable
      let formData = new FormData();

      const credentialsJson = JSON.stringify(credentials);
      formData.append("credentials_txt", credentialsJson);

      const userJson = JSON.stringify(this.new_user);
      formData.append("user_txt", userJson);

      if (this.file_01 != null) {
        formData.append("file_01", this.file_01);
      }

      axios
        .post(url, formData)
        .then(function(result) {
          let data = result.data;

          if (data.response.success === true) {
            const authData = {
              tokenId: data.tokenId,
              userToken: data.user_context.user.user_token,
              userData: data.user_context.user
            };

            context.new_user = data.response.user;
            store.commit("authUser", authData);
            router.go(-1);
          } else {
            store.dispatch("logout");
            router.replace("/login");
          }
        })
        .catch(function(error) {
          error;
        });
    }
  },
  mounted() {
    this.user = this.$store.state.userData;
    this.admin_level = this.user.admin_level;
    this.admin_levels = this.$store.state.admin_levels;

    this.new_user = JSON.parse(JSON.stringify(this.new_user_init));
    this.new_user_selected = JSON.parse(JSON.stringify(this.new_user_init));

    this.profil_type = this.$route.query.type;
    if (this.$route.query.group_token) {
      this.group_token = this.$route.query.group_token;
    }

    if (this.profil_type == "group_manager") {
      this.getGroups();
      return;
    }

    if (this.profil_type == "team_manager") {
      let objs = [];
      let periods = this.$store.state.circleData.params.periods;

      for (let i = 0; i < periods.length; i++) {
        if (periods[i] == "week") {
          objs.push({
            name: td("views.teams.new_user.week"),
            value: "week"
          });
        }
        if (periods[i] == "month") {
          objs.push({
            name: td("views.teams.new_user.month"),
            value: "month"
          });
        }
        if (periods[i] == "trim") {
          objs.push({
            name: td("views.teams.new_user.trim"),
            value: "trim"
          });
        }
        if (periods[i] == "quadri") {
          objs.push({
            name: td("views.teams.new_user.qiadri"),
            value: "quadri"
          });
        }
        if (periods[i] == "sem") {
          objs.push({
            name: td("views.teams.new_user.semester"),
            value: "sem"
          });
        }
        if (periods[i] == "year") {
          objs.push({
            name: td("views.teams.new_user.year"),
            value: "year"
          });
        }
      }
      this.new_objectifs_options = objs;
      this.getTeams();
      return;
    }
    this.getUsers();
  },
  created() {}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
